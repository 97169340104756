// import { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/logo.png'
import './Header.css'
import NavMenu from "./NavMenu";

const Header = () => {


    return (
        <>
            <header id="header">
                <Link to="/home">
                    <img className="logoHome" src={logo} alt="Weeabrewing" />
                </Link>

                <NavMenu/>

            </header>
        </>
    )
}

export default Header