import bottle from './assets/tsundereBottle.webp'
import logo from './assets/tsundereLogo.png'

const Tsundere = () => {
    return (
        <div className="beer" id="tsundere">

            <img
                id="bottle"
                alt="tsundere Kombucha Logo/Bottle Placeholder"
                src={bottle} />

            <img
                id="logo"
                alt='Tsundere Logo'
                src={logo} />

            <div id="beerDescription" >
                    <p>Seasonal Saison</p> 
                    <p>6.9%</p>
                    <p>A sour and sweet saison that you'll hate that you love</p>
            </div>
        </div>
    )
}

export default Tsundere;