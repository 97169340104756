import './Footer.css'

const Footer = () => {
    return (<>
        <footer className='footer-container' id="footer">
            ©2021 Weeabrew Brewing Co. All Rights Reserved.
            {/* {<a href="/">home</a>} */}
        </footer>
    </>)
}

export default Footer