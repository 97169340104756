import './App.css';
// import { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Link
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {theme} from './theme';

import {
  AgeVerify,
  Header, 
  Brews,
  Weebs,
  Careers, 
  Footer, 
  Contact
} from './components';

// import logo from './assets/logo.png'


function App() {

  // const [verifyAge, setVerifyAge] = useState(null)
  // const [open, setOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path = "/">
                <AgeVerify/>
              </Route>

              <Route exact path ='/home'>
                <Header/>
              </Route>

              <Route exact path = '/brews'>
                <Brews Header={Header} Footer={Footer} />
              </Route>

              <Route exact path = '/weebs' >
                <Weebs Header={Header} Footer={Footer}/>
              </Route>
              
              <Route exact path = '/careers' >
                <Careers Header={Header} Footer={Footer} />
              </Route>

              <Route exact path = '/contact'>
                <Contact Header={Header} Footer={Footer} />
              </Route>
            </Switch>

            
          </Router>
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;