import logo from './assets/HoppaiLogoAlpha.png'
import bottle from './assets/hoppaiBottle.webp'

const Hoppai = () => {
    return (
        // <div className="beer" id="hoppai">
        //     <img src={pic} alt="Hoppai"></img>
        // </div>

        <div className="beer" id="hoppai">
        
        <img
            id="bottle"
            alt="Hoppai bottle" 
            src={bottle} />
        
        <img 
            id="logo"
            alt="Hoppai Logo"
            src={logo} />

        <div id="beerDescription" >
            <p>Pale Ale</p> 
            <p>6.9%</p>
            <p>A pale with two handfuls of hops</p>
        </div>
    </div>
    )
}

export default Hoppai;