import aj from './assets/aj.webp'

const Algernon = () => {
    return (
        <div className="weeb" id="Algernon">
            <img 
                id="ajPic"
                alt="Flowers for Algernon"
                src={aj} />
        </div>
    )
}

export default Algernon;