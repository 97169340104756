import Alehegao from "./Alehegao"
import Kouhai from "./Kouhai"
import Hoppai from "./Hoppai"
import Kimochi from "./Kimochi"
import NoticeMead from "./NoticeMead"
import Tsundere from "./Tsundere"
import './Brews.css'

const Brews = ({Header, Footer}) => {
    return (
        <>
            <Header/>
            <div id='brews-container'>
                <div className="pageHeader">OUR BREWS</div>
                <Alehegao/>
                <Kouhai/>
                <Hoppai/>
                <Kimochi/>
                <NoticeMead/>
                <Tsundere/>
            </div>
            <Footer/>
        </>
    )
}

export default Brews