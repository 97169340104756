import pic from './assets/Kaylah.webp';
// import name from './assets/kaylahLogo.png'

const Kaylah = () => {
    return (
        <div className="weeb" id="Kaylah">


            <img 
                id="kaylahPic"
                alt="Kaylah"
                src={pic} />

            {/* <div className="weebDescription">
                <h1>Drunkely came up with the idea of a hentai beer</h1>
            </div> */}
        </div>
    )
}

export default Kaylah;