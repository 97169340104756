import bottle from './assets/kimochiBottlePic.webp';
import logo from './assets/kimochiKanji.png'
import mobileLogo from './assets/kimochiLogo.png'

const Kimochi = () => {
    return (
        <div className="beer" id="kimochi">

            <img
                id="bottle"
                alt="Kimochi Kombucha Bottle"
                src={bottle} />

            <img
                id="kimochiLogo"
                alt='Kimochi Kombucha Logo'
                src={logo} />

            <img 
                id="mobileLogo"
                alt='Kimochii Kombucha Logo'
                src={mobileLogo}
            />

            <div id="kimochiDescription" >
                    <p>Kombucha</p> 
                    <p>6.9%</p>
                    <p>A blood orange kombucha that will make you feel oh so good {"<"}3</p>
            </div>
        </div>
    )
}

export default Kimochi