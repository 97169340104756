import logo from './assets/kouhaiLogo.png';
import bottle from './assets/kreaminBottle.webp';

const Kouhai = () => {
    return (
        <div className="beer" id="kouhai">
        
            <img
                id="bottle"
                alt="Kouhai bottle" 
                src={bottle} />
            
            <img 
                id="logo"
                alt="Kouhai Logo"
                src={logo} />
                
            <div id="beerDescription" >
                <p>A Not-so-Vanilla Cream Ale</p> 
                <p>6.9%</p>
                <p>A creamy ale with a just hint of vanilla</p>
            </div>

        </div>
    )
}

export default Kouhai