import { Link } from "react-router-dom"
import resume from '../../assets/timoResume.pdf'
import './Careers.css'

const Careers = ({Header, Footer}) => {
    return (
        <>
            <Header />
            <div className="pageHeader">Careers</div>

            <div className='careers-container'>
                <div className="hireMe">We don't actually have any jobs to offer you, but
                    if you need a Full Stack Web Developer, please consider Timothy. He made this horrible site, 
                    took all the pictures, brewed the beer, and designed all the product labels.


                    <div id='timContacts'>You can 
                        {<Link to={resume} target="_blank" id='timContacts'> DOWNLOAD </Link>} 
                        his resume or email him at 
                        {<a id='timContacts' href={"mailto:tromgalvez@gmail.com"}> tromgalvez@gmail.com </a>}
                        or contact him on {<a id="timContacts" href='http://linkedin.com/in/timothy-galvez'>LinkedIn</a>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Careers
