import logo from './assets/AlehegaoLogo.png'
import bottle from './assets/AlehegaoBottle.webp'

const Alehegao = () => {
    return (
        <div className="beer" id="alehegao">
        
            <img
                id="bottle"
                alt="Alehegao bottle" 
                src={bottle} />
            
            <img 
                id="logo"
                alt="Alehegao Logo"
                src={logo} />

            <div id="beerDescription" >
                <p>Brown Ale</p> 
                <p>6.9%</p>
                <p>An ale so good you'll start making funny faces</p>
            </div>
        </div>
    )
}

export default Alehegao