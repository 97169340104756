import headerLogo from '../assets/logo.png'
import './AgeVerification.css'
import yes from '../assets/yes.png'
import no from '../assets/nyooo.png'
import inside from '../assets/comingInside.png'
import mouf from '../assets/moufLogoSVG.svg'

const AgeVerify = () => {
    
    return (
        <div id="age-clearance">
            <img className="logoHeader" src={headerLogo} alt="Weeabrew Brewing Company" />
            <img className="App-logo" src={mouf} alt="what dat mouf do" />
            <div className="choices">    
            <h2>ARE YOU LEGAL?</h2>
                <a 
                    href="/home"   
                    id="yes" >
                    <img id="yes" src={yes} alt="yes"/>
                    <img src={inside} className="inside" alt="AAAHHHH YOU'RE COMING INSIDE?!!" />
                </a>
                <a href="https://www.chuckecheese.com/" id="no"><img id="no" src={no} alt="NYOOO" /></a>
            </div>
        </div>
    )
};

export default AgeVerify;
