import Kaylah from "./Kaylah"
import Algernon from "./Algernon"
import './weebs.css'
import me from './assets/jimothy.webp'


const Weebs = ({Header, Footer}) => {
    return (
        <>
            <Header/>
            <div className="pageHeader" >
                THE WEEBS</div>
            <div className="weeb-container">
                
                <div className='weebDescription'>
                    <h2>Some weebs got drunk and came up with a hentai beer, and now we're a brewery.</h2>
                </div>
                <Kaylah/>
                <Algernon/>
                <img src={me} id='me' className="weeb" alt='Jimothy' />

            </div>
            <Footer/>
        </>
    )
}

export default Weebs