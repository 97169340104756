import React, {useState} from 'react';
import { Grid, TextField, Button, FormControl } from "@material-ui/core";
import axios from 'axios';
import './Contact.css';
import {SocialIcon} from 'react-social-icons'

const Contact = ({Header, Footer}) => {

    // 1ST VERSION START

    const [data, setData] = useState({name: '', email:'', message: '', sent: false, buttonText: 'Submit', err: ''});

    const handleChange = (e) => {
        const {name, value} = e.target
            setData({
                ...data,
                [name]: value
        })
    }

    const resetForm = () => {
        setData({
            name: '', 
            email: '', 
            message: '', 
            sent: false, 
            buttonText: 'Submit',
            err: ''
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();

        setData({
            ...data, 
            buttonText: 'Sending....'
        })

        axios.post('/api/sendmail', data)
            .then(res=> {
                if(res.data.result !== 'success') {
                    setData({
                        ...data,
                        buttonText: 'Failed to Send',
                        sent: false,
                        err: 'fail'
                    })
                    setTimeout(()=>{
                        resetForm()
                    }, 6000)
                } else {
                    setData({
                        ...data,
                        sent: true,
                        buttonText: 'Sent',
                        err: 'success'
                    })
                    setTimeout(()=> {
                        resetForm();
                    }, 6000)
                }
            }).catch((err)=> {
                console.log(err.response.status)
                setData({
                    ...data,
                    buttonText: "Message Sent!", 
                    err: 'fail'
                })
            })
    }

    return (
        <>
            <Header/>

            <div className="pageHeader">CONTACT US</div>

            <div className='contactDescription'>
                <h2>Need to get senpai's attention? Contact us at 
                    {<a id='senpai' href='mailto:senpai@weeabrewing.com'> senpai@weeabrewing.com</a>}, 
                    {<br></br>}
                    on Social Media,</h2> 
                    
                    <div className='iconContainer'>
                        <SocialIcon url='discord.com' bgColor='#fa9089' fgColor='#F5F5DC' />
                        <SocialIcon url='twitter.com' bgColor='#fa9089' fgColor='#F5F5DC' />
                        <SocialIcon url='https://www.instagram.com/spiffytimo/' bgColor='#fa9089' fgColor='#F5F5DC' />
                    </div>
                <h2>or on the form below</h2>
            </div>



            <div className="form-container">
                <FormControl fullWidth={false}>
                    <TextField required label="Full name" variant="filled" id="full-name" name="name" className="form-field" value={data.name} onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth={false}>
                    <TextField required label="Email" id="email" name="email" variant="filled" className="form-field" value={data.email} onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth={false} id="message" >
                    <TextField required label="Message" variant="filled" name="message" multiline={true} rows="10" value={data.message} onChange={handleChange} />
                </FormControl>
                <FormControl>
                    <div style={{padding: 20}}>
                        <Grid container spacing={2}>
                            <div className="form-submit">
                                <Button variant="contained" color="primary" onClick={formSubmit}>{data.buttonText}</Button>
                            </div>
                        </Grid>
                    </div>
                </FormControl>
            </div>
            <Footer/>
        </>
    )
    // 1ST VERSION END


    // 2ND VERSION START
    // return (
    //     <>
    //     <form action='{{SERVICE URL}}' method='POST'>
    //         <label>
    //             Name
    //             <input type="text" name="name" required></input>
    //         </label>

    //         <label>
    //             Email
    //             <input type="email" name="reply_to" required></input>
    //         </label>

    //         <label>
    //             Message:
    //             <textarea name="message" required></textarea>
    //         </label>

    //         <button type='submit'>Send Message</button>
    //     </form>
    //     <p id='js-form-response'></p>
    //     </>
    // )
    // 2ND VERSION END
}

export default Contact;