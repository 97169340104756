import pic from './assets/noticeMead.webp'

const NoticeMead = () => {
    return (
        <div className="beer" id="noticeMead">
            <img src={pic} alt="Notice Mead Senpai"></img>
        </div>
    )
}

export default NoticeMead