import React, {useState} from "react";
import './NavMenu.css';
import { Link } from "react-router-dom";
// import {MdClose} from 'react-icons/md';
// import {FiMenu} from 'react-icons/fi';
import {FaHeartBroken} from 'react-icons/fa';
import {FaHeart} from 'react-icons/fa';
import logo from '../../assets/logo.png'

const NavMenu = () => {

    const [navOpen, setNavOpen] = useState(false);
    const handleToggle = () => {
        setNavOpen(!navOpen)
    }
    const closeMenu = () =>{
        setNavOpen(false)
    }

    return(
        <>
            <nav className="navMenu">
                <button onClick={handleToggle}>
                {navOpen ? (
                    <FaHeartBroken style={{ color: "#fff", width: "25px", height: "auto" }} />
                ) : (
                    <FaHeart style={{ color: "#7b7b7b", width: "25px", height: "auto" }} />
                )}
                </button>
                <ul className={`menuNav ${navOpen ? ' showMenu': ''}`}>
                    <li><Link 
                        to="/home" 
                        onClick={()=>closeMenu()}
                    ><img className="logoNav" src={logo} alt="Weeabrewing" /></Link></li>


                    <li><Link 
                        to="/brews"
                        className="navItem" 
                        onClick={()=>closeMenu()}
                    >Brews</Link></li>

                    <li><Link 
                        to="/careers"
                        className="navItem"
                        onClick={()=>closeMenu()}
                    >Careers</Link></li>

                    {/* <li><Link 
                        to="/merch"
                        className="navItem"
                        onClick={()=>closeMenu()}
                    >Merch</Link></li> */}

                    <li><Link 
                        to="/weebs"
                        className="navItem"
                        onClick={()=>closeMenu()}
                    >Meet the Weebs</Link></li>
                    
                    <li><Link 
                        to="/contact"
                        className="navItem"
                        onClick={()=>closeMenu()}
                    >Stalk Us</Link></li>
                </ul>
            </nav>
        </>
    )
}

export default NavMenu;